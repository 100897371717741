import { useEffect, useState } from "react";
import { CarSlider, Weather, Map, BannerSlider } from "../../components";
import iLocation from "../../assets/icons/iLocation.png";

import Car from "../../assets/logos/car.png";
const Index = (props) => {
  const [selectedCar, setSelectedCar] = useState(null);
  const [info, setInfo] = useState({});
  useEffect(() => {
    props.request({
      url: `/user/cars`,
      model: "carsinfo",
      method: "POST",
    });
    props.request({
      url: `/timer/list`,
      model: "timerlist",
    });
    props.request({
      url: `/alarm/list`,
      model: "alarmlist",
    });
    props.request({ url: "/user/checklic", model: "licenseinfo" });
  }, []);

  useEffect(() => {
    if (!selectedCar && props?.rescarsinfo && props?.rescarsinfo[0])
      setSelectedCar(props.rescarsinfo[0]);
  }, [props?.rescarsinfo]);

  const getComp = () => {
    if (props?.iscarsinfo === true) {
      return (
        <div className="flex flex-col items-center justify-center h-full p-10">
          <img src={Car} alt="Car" className="w-3/6" />
          <h2 className="text-2xl font-bold text-center text-gray-700 dark:text-white">
            Түр хүлээнэ үү....
          </h2>
        </div>
      );
    } else if (!props?.rescarsinfo || props?.rescarsinfo?.length === 0) {
      return (
        <div className="flex flex-col items-center justify-center h-full p-10">
          <img src={Car} alt="Car" className="w-3/6" />
          <h2 className="text-2xl font-bold text-center text-gray-700 dark:text-white">
            {props?.translate("main.nocar")}
          </h2>

          <p className="mt-3 text-gray-500 dark:text-gray-300 text-center">
            {props?.translate("main.nocardesc")}
          </p>
        </div>
      );
    } else {
      return (
        <CarSlider
          data={props?.rescarsinfo}
          {...props}
          selectedCar={selectedCar}
          setSelectedCar={setSelectedCar}
          info={info}
          setInfo={setInfo}
        />
      );
    }
  };

  return (
    <div className="grid grid-cols-12 gap-2 sm:gap-2 md:gap-8 h-full overflow-y-auto">
      <div className="h-full bg-white dark:bg-card col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-7 xl:col-span-6 2xl:col-span-5 rounded-2xl border shadow-lg border-light_border dark:border-border ">
        {getComp()}
      </div>
      <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-5 xl:col-span-6 2xl:col-span-7 flex flex-col gap-2 sm:gap-2 md:gap-8">
        <div className="col-span-12 bg-white dark:bg-card rounded-2xl border shadow-lg border-light_border dark:border-border p-8 flex flex-col">
          <div className="flex flex-col md:flex-row md:items-center">
            <span className="font-bold text-2xl">
              {props.translate("main.weather")}
            </span>
            <div className="flex items-center md:ml-6">
              <img src={iLocation} alt="iLocation" className="icon w-4 h-4 " />
              <span className="ml-2">{props.translate("main.location")}</span>
            </div>
          </div>
          <Weather
            {...props}
            loading={props?.isweather === true}
            data={
              props?.isweather === true
                ? { temperature_2m: new Array(24).fill(null) }
                : props?.resweather?.hourly
            }
          />
        </div>
        <div className="col-span-12 h-96 md:h-full dark:bg-card rounded-2xl border shadow-lg border-light_border dark:border-border p-4">
          {selectedCar?.islocation ? (
            <Map selectedCar={selectedCar} info={info} {...props} />
          ) : (
            <BannerSlider data={props?.resbanners} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Index;
