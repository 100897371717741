import { IsEnable, UserType } from "../enums";
import {
  CONFIRMPASSWORD_VALIDATOR,
  CREATEPASSWORD_VALIDATOR,
} from "../utils/validations";
export const ISENABLE = [
  {
    label: "Идэвхтэй",
    value: IsEnable.Идэвхтэй,
  },
  {
    label: "Идэвхгүй",
    value: IsEnable.Идэвхгүй,
  },
];

export const ORDERSTATUS = [
  {
    label: "Баталсан",
    value: 1,
  },
  {
    label: "Цуцалсан",
    value: 2,
  },
  {
    label: "Үүссэн",
    value: 0,
  },
];

export const UserTypeList = [
  {
    label: "Хэрэглэгч",
    value: UserType.Хэрэглэгч,
  },
  {
    label: "Супер админ",
    value: UserType.Супер_админ,
  },
  {
    label: "Админ",
    value: UserType.Админ,
  },
];

export const SUCCESSORFAIL = [
  {
    label: "Амжилттай",
    value: IsEnable.Идэвхтэй,
  },
  {
    label: "Амжилтгүй",
    value: IsEnable.Идэвхгүй,
  },
  {
    label: "Архив",
    value: 2,
  },
];

export const CARTYPE = [
  {
    label: "Sedan",
    value: 1,
  },
  {
    label: "Cuv",
    value: 2,
  },
  {
    label: "Suv",
    value: 3,
  },
  {
    label: "Hatchback",
    value: 4,
  },
  {
    label: "Van",
    value: 4,
  },
];

export const YESNO = [
  {
    label: "Тийм",
    value: 1,
  },
  {
    label: "Үгүй",
    value: 0,
  },
];

export const ISKEY = [
  {
    label: "Кноп",
    value: 0,
  },
  {
    label: "Түлхүүр",
    value: 1,
  },
];

export const OPERATION = [
  {
    label: "Мобиком",
    value: 1,
  },
  {
    label: "Юнител",
    value: 2,
  },
  {
    label: "Жи-мобайл",
    value: 3,
  },
];

export const LOGIN_FORM = [
  {
    type: "input",
    placeholder: "Утасны дугаар",
    name: "name",
    size: "large",
    required: true,
  },
  {
    type: "inputpassword",
    placeholder: "Нууц үг",
    name: "pass",
    size: "large",
    required: true,
  },
  {
    type: "checkbox",
    valuePropName: "checked",
    placeholder: "Сануулах",
    name: "checkbox",
  },
];

export const FEEDTYPE = [
  {
    label: "Санал хүсэлт",
    value: 1,
  },
  {
    label: "Урам",
    value: 2,
  },
  {
    label: "Асуулт",
    value: 3,
  },
  {
    label: "Шүүмж",
    value: 4,
  },
  {
    label: "Програмын алдаа",
    value: 5,
  },
];

export const TIMER_ADD_FORM = [
  {
    list: [
      {
        type: "select",
        placeholder: "Цаг",
        options: Array.from({ length: 24 }, (_, index) => {
          return {
            label: index === 0 ? "00" : index,
            value: index,
          };
        }),
        name: "cyclehour",
        required: true,
      },
      {
        type: "select",
        placeholder: "Минут",
        options: Array.from({ length: 60 }, (_, index) => {
          return {
            label: index,
            value: index,
          };
        }),
        name: "cycleminute",
        required: true,
      },
      {
        type: "select",
        placeholder: "Минут асна",
        options: Array.from({ length: 60 }, (_, index) => {
          return {
            label: index + 1,
            value: index + 1,
          };
        }),
        name: "startminute",
        required: true,
      },
    ],
  },
  {
    type: "select",
    placeholder: "Төлөв",
    options: ISENABLE,
    name: "isenable",
    required: true,
  },
];

export const ALARM_ADD_FORM = [
  {
    type: "select",
    placeholder: "Машин",
    options: "carsinfo",
    fieldNames: {
      label: "number",
      value: "id",
    },
    name: "carid",
    required: true,
  },
  {
    list: [
      {
        type: "select",
        placeholder: "Цаг",
        options: Array.from({ length: 24 }, (_, index) => {
          return {
            label: index === 0 ? "00" : index,
            value: index,
          };
        }),
        name: "time",
        required: true,
      },
      {
        type: "select",
        placeholder: "Минут",
        options: Array.from({ length: 60 }, (_, index) => {
          return {
            label: index,
            value: index,
          };
        }),
        name: "minut",
        required: true,
      },
      {
        type: "select",
        placeholder: "Минут асна",
        options: Array.from({ length: 60 }, (_, index) => {
          return {
            label: index + 1,
            value: index + 1,
          };
        }),
        name: "startminute",
        required: true,
      },
    ],
  },
  {
    type: "select",
    placeholder: "Өдөр",
    mode: "multiple",
    showSearch: false,
    options: [
      { value: 1, day: "Даваа" },
      { value: 2, day: "Мягмар" },
      { value: 3, day: "Лхагва" },
      { value: 4, day: "Пүрэв" },
      { value: 5, day: "Баасан" },
      { value: 6, day: "Бямба" },
      { value: 7, day: "Ням" },
    ],
    fieldNames: {
      label: "day",
      value: "value",
    },
    name: "days",
    required: true,
  },
  {
    type: "select",
    placeholder: "Төлөв",
    options: ISENABLE,
    name: "isenable",
    required: true,
  },
];

export const ADD_PRODUCT_FORM = [
  {
    type: "select",
    placeholder: "control.category",
    options: "categories",
    fieldNames: {
      label: "name",
      value: "id",
    },
    name: "catid",
    required: true,
  },
  {
    type: "input",
    placeholder: "control.name",
    name: "name",
    required: true,
  },
  {
    type: "numberinput",
    placeholder: "control.price",
    name: "price",
    required: true,
  },
  {
    type: "textarea",
    placeholder: "control.textarea",
    name: "description",
    required: true,
  },
  {
    type: "select",
    placeholder: "control.status",
    options: ISENABLE,
    name: "isenable",
    required: true,
  },
  {
    type: "upload",
    placeholder: "control.image",
    name: "img",
    required: true,
  },
];

export const USER_FORM = [
  {
    type: "input",
    placeholder: "control.username",
    disabled: true,
    name: "username",
    required: true,
  },
  {
    type: "input",
    placeholder: "control.phoneno",
    disabled: true,
    name: "phone",
    required: true,
  },
];

export const SET_PIN_FORM = [
  {
    type: "password",
    placeholder: "control.password",
    name: "password",
    required: true,
  },
];

export const SET_USER_LOCATION = [
  {
    type: "input",
    placeholder: "control.car",
    name: "carid",
    required: true,
    hidden: true,
  },
  {
    type: "select",
    placeholder: "control.user",
    options: "carusers",
    mode: "multiple",
    fieldNames: {
      label: "username",
      value: "id",
    },
    name: "userid",
    /* required: true, */
  },
];

export const SET_START_TIME = [
  {
    type: "input",
    placeholder: "control.car",
    name: "carid",
    required: true,
    hidden: true,
  },
  {
    type: "select",
    placeholder: "Асах хугацаа",
    options: Array.from({ length: 61 }, (_, index) => {
      return {
        label: index,
        value: index,
      };
    }),
    name: "minute",
    required: true,
  },
];

export const SET_ADMIN_NUMBER = [
  {
    type: "input",
    placeholder: "control.car",
    name: "carid",
    required: true,
    hidden: true,
  },
  {
    type: "input",
    placeholder: "control.car",
    name: "imei",
    required: true,
    hidden: true,
  },
  {
    type: "input",
    placeholder: "Утасны дугаар",
    name: "number",
    required: true,
  },
];

export const CHANGE_PASSWORD = [
  {
    type: "inputpassword",
    placeholder: "control.oldpassword",
    name: "oldpass",
    required: true,
  },
  {
    type: "inputpassword",
    placeholder: "control.password",
    name: "password",
    required: true,
    rules: CREATEPASSWORD_VALIDATOR,
  },
  {
    type: "inputpassword",
    placeholder: "control.confirmpass",
    name: "confirmpassword",
    required: true,
    rules: CONFIRMPASSWORD_VALIDATOR,
  },
];

export const EDIT_PERMISSION = [
  {
    type: "select",
    placeholder: "Төрөл",
    options: UserTypeList,
    name: "type",
    required: true,
  },
];

export const ADD_CAR_FORM = [
  {
    type: "input",
    placeholder: "main.carname",
    name: "name",
    required: true,
  },
  {
    type: "input",
    placeholder: "main.carnum",
    name: "number",
    required: true,
  },
  {
    type: "select",
    placeholder: "main.type",
    options: CARTYPE,
    name: "type",
    required: true,
  },
  {
    type: "input",
    placeholder: "IMEI",
    name: "imei",
    required: true,
  },
  {
    type: "select",
    placeholder: "main.carislock",
    options: YESNO,
    name: "islock",
    required: true,
  },
  {
    type: "select",
    placeholder: "main.iskey",
    options: ISKEY,
    name: "iskey",
    required: true,
  },
  {
    type: "select",
    placeholder: "main.operation",
    options: OPERATION,
    name: "operator",
    required: true,
  },
  {
    type: "select",
    mode: "multiple",
    placeholder: "main.adminuser",
    options: "customusers",
    name: "adminuserid",
    fieldNames: {
      label: "username",
      value: "userid",
    },
  },
  {
    type: "select",
    placeholder: "control.status",
    options: ISENABLE,
    name: "isenable",
    required: true,
  },
];

export const ADD_BANNER_FORM = [
  {
    type: "upload",
    placeholder: "Зураг",
    name: "img",
    required: true,
  },
  {
    type: "upload",
    placeholder: "Логин зураг",
    name: "loginimg",
    required: true,
  },
  {
    type: "date",
    placeholder: "Эхлэх огноо",
    name: "sdate",
    required: true,
  },
  {
    type: "date",
    placeholder: "Дуусах огноо",
    name: "edate",
    required: true,
  },
  {
    type: "select",
    placeholder: "Төлөв",
    options: ISENABLE,
    name: "isenable",
    required: true,
  },
];

export const CONCAR_FORM = [
  {
    type: "select",
    placeholder: "main.car",
    options: "carspopup",
    mode: "multiple",
    fieldNames: {
      label: "number",
      value: "id",
    },
    name: "carids",
    optionFilterProp: "number",
  },
];

export const CONUSER_FORM = [
  {
    type: "select",
    placeholder: "main.user",
    options: "userspopup",
    mode: "multiple",
    fieldNames: {
      label: "username",
      value: "id",
    },
    name: "userids",
  },
];

export const ADD_LICENSE_FORM = [
  {
    type: "select",
    placeholder: "main.user",
    options: "userspopup",
    allowClear: true,
    showSearch: true,
    optionFilterProp: "username",
    fieldNames: {
      label: "username",
      value: "id",
    },
    name: "userid",
  },
  {
    placeholder: "Сунгах хоног",
    type: "numberinput",
    name: "day",
    required: true,
  },
  {
    placeholder: "Төлсөн дүн",
    type: "numberinput",
    name: "amount",
    required: true,
  },
];

export const CATEGORY_ADD_FORM = [
  {
    type: "input",
    placeholder: "main.catname",
    name: "name",
    required: true,
  },
  {
    type: "select",
    placeholder: "Төлөв",
    options: ISENABLE,
    name: "isenable",
    required: true,
  },
];

export const FEEDBACK_ADD_FORM = [
  {
    type: "select",
    placeholder: "main.type",
    options: FEEDTYPE,
    name: "type",
    required: true,
  },
  {
    type: "textarea",
    placeholder: "sider.feedback",
    name: "text",
    required: true,
  },
];

export const NOTIF_ADD_FORM = [
  {
    type: "radio",
    options: [
      {
        value: true,
        label: "main.allusers",
      },
      {
        value: false,
        label: "main.onlyuser",
      },
    ],
    defaultValue: true,
    name: "type",
  },
  {
    type: "select",
    isShowOrFalse: true,
    placeholder: "Хэрэглэгч",
    mode: "multiple",
    optionFilterProp: "username",
    options: "props.resuserspopup",
    fieldNames: {
      label: "username",
      value: "id",
    },
    name: "userid",
  },
  {
    type: "input",
    placeholder: "main.title",
    name: "header",
    required: true,
  },
  {
    type: "input",
    placeholder: "sider.notification",
    inputtype: "textarea",
    name: "body",
    required: true,
  },
];

export const ADD_DEVICE_FORM = [
  {
    type: "input",
    placeholder: "control.name",
    name: "name",
    required: true,
  },
  {
    type: "input",
    placeholder: "control.textarea",
    inputtype: "textarea",
    name: "description",
    required: true,
  },
  {
    type: "input",
    placeholder: "control.link",
    name: "link",
    required: true,
  },
  {
    type: "numberinput",
    placeholder: "control.servicehour",
    name: "hour",
    required: true,
  },
  {
    type: "select",
    placeholder: "control.status",
    options: ISENABLE,
    name: "isenable",
    required: true,
  },
];

export const ADD_SCHEDULE_FORM = [
  {
    type: "date",
    placeholder: "control.date",
    name: "date",
    required: true,
  },
  {
    type: "select",
    placeholder: "Нээх цаг",
    options: Array.from({ length: 10 }, (_, index) => {
      const value = index + 6;
      return {
        label: value,
        value: value,
      };
    }),
    name: "starthour",
    required: true,
  },
  {
    type: "select",
    placeholder: "Хаах цаг",
    options: Array.from({ length: 15 }, (_, index) => {
      const value = index + 10;
      return {
        label: value,
        value: value,
      };
    }),
    name: "endhour",
    required: true,
  },
  {
    type: "select",
    placeholder: "Нэмэлт цаг",
    options: Array.from({ length: 15 }, (_, index) => {
      const value = index + 10;
      return {
        label: value,
        value: value,
      };
    }),
    name: "taxhour",
  },
  {
    type: "numberinput",
    placeholder: "Ажиллах ажилчны тоо",
    name: "staffqty",
    required: true,
  },
  {
    type: "numberinput",
    placeholder: "Хураамж",
    name: "amount",
    required: true,
  },
];

export const PAYMENT_EDIT_FORM = [
  {
    type: "select",
    placeholder: "main.archive",
    options: ISENABLE,
    name: "isarchive",
    required: true,
  },
  {
    type: "select",
    placeholder: "main.user",
    options: "userspopup",
    fieldNames: {
      label: "username",
      value: "id",
    },
    name: "userid",
  },
  {
    type: "input",
    placeholder: "main.day",
    name: "day",
  },
  {
    placeholder: "main.desc",
    type: "textarea",
    name: "note",
  },
  {
    placeholder: "statementid",
    disabled: true,
    type: "inputnumber",
    name: "statementid",
  },
];
