import { useEffect, useState } from "react";
import Car from "../../assets/logos/car.png";
import CarControl from "./carcontrol";
import CarInfo from "./carinfo";
import { Timeline } from "antd";

const Index = (props) => {
  const [timeLeft, setTimeLeft] = useState(null);
  const [demoStarted, setDemoStarted] = useState(false);
  let timer = props?.restimerlist?.filter((x) => x.carid === props?.data?.id);
  let alarm = props?.resalarmlist?.filter((x) => x.carid === props?.data?.id);
  useEffect(() => {
    timer = props?.restimerlist?.filter((x) => x.carid === props?.data?.id);
    alarm = props?.resalarmlist?.filter((x) => x.carid === props?.data?.id);
  }, [props?.resalarmlist, props?.restimerlist]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (props?.info[props?.data?.imei]?.isactive) {
        props
          ?.request({ url: `/user/checkpower/${props?.data?.id}` })
          ?.then((res) => {
            if (res?.success) {
              setTimeLeft(calculateTimeLeft(res?.data));
            } else setTimeLeft(null);
          });
      }
    }, 1000);
    return () => clearInterval(timer);
  }, [props?.info[props?.data?.imei]?.isactive]);

  const calculateTimeLeft = (powerTime) => {
    if (powerTime) {
      const difference = new Date(powerTime?.offymd) - new Date(powerTime?.now);
      let timeLeft = {};

      if (difference > 0) {
        timeLeft = {
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: Math.floor((difference / 1000) % 60),
        };
      }

      return timeLeft;
    }
    return null;
  };

  const getCarActive = () => {
    if (props?.isDemo) {
      return demoStarted
        ? props?.translate("main.turnedon")
        : props?.translate("main.turnedoff");
    } else {
      return props?.info[props?.data?.imei]?.isactive
        ? props?.translate("main.turnedon")
        : props?.translate("main.turnedoff");
    }
  };
  /*   useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    // Cleanup the timer
    return () => clearInterval(timer);
  }, [powerTime]); */

  return (
    <div className="p-0 md:p-5 lg:p-5 xl:p-5 h-full flex items-center justify-center">
      <div className={`flex flex-col items-center p-5`}>
        <img src={Car} alt="Car" className="w-3/6" />
        <div className="flex flex-col items-center mt-2">
          <span className="font-bold text-2xl">{props?.data?.name}</span>
          <span>{props?.data?.number}</span>
          <span className="text-xs">{getCarActive()}</span>
          {timeLeft && props?.info[props?.data?.imei]?.isactive ? (
            <span className="text-danger">{`${timeLeft?.minutes
              ?.toString()
              ?.padStart(2, "0")}:${timeLeft?.seconds
              ?.toString()
              ?.padStart(2, "0")}`}</span>
          ) : null}

          {props?.connected[props?.data?.imei] ? null : (
            <span className="mt-2 text-red-500">
              Машин дотор байгаа дугаарын хугацааг шалгана уу
            </span>
          )}
        </div>
        <div
          className={`${
            props?.isDemo
              ? "blur-none"
              : props?.connected[props?.data?.imei]
              ? "blur-none"
              : "blur-sm pointer-events-none"
          }`}
        >
          <CarControl
            className="mt-4"
            {...props}
            sendCommand={props?.sendCommand}
            loading={props?.loading}
            setLoading={props?.setLoading}
            setDemoStarted={setDemoStarted}
            demoStarted={demoStarted}
          />
          <CarInfo
            className="mt-4"
            {...props}
            timer={timer && timer[0]}
            alarm={alarm && alarm[0]}
          />
        </div>
      </div>
    </div>
  );
};

export default Index;
