import iTemp from "../../assets/icons/iTemp.png";
import iVolt from "../../assets/icons/iVolt.png";
import iTime from "../../assets/icons/iTime.png";
import iG from "../../assets/icons/iG.png";
import { Cards } from "..";
import { Button, Popover } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
const Index = (props) => {
  const getSimDate = () => {
    const text = props?.siminfo[props?.data?.imei];
    if (text) {
      const extractedDate = text.match(/\d{4}\/\d{2}\/\d{2}/)?.[0] || "";
      if (extractedDate) {
        const expiryDate = dayjs(extractedDate, "YYYY/MM/DD");
        const today = dayjs();
        const diffDays = expiryDate.diff(today, "day");
        if (diffDays <= 10 && diffDays >= 0) {
          return (
            <div className="flex flex-col text-red-600">
              <span className="text-red-600">{text}</span>
              <span className="text-xs">Симний хугацаа дуусах гэж байна</span>
            </div>
          );
        } else if (diffDays < 0) {
          return (
            <div className="flex flex-col text-red-600">
              <span className="text-red-600">{text}</span>
              <span className="text-xs">Симний хугацаа дуусан байна</span>
            </div>
          );
        } else {
          return <span>{text}</span>;
        }
      } else {
        return <span>{text}</span>;
      }
    }

    return <span>Симний мэдээлэл татагдаагүй байна.</span>;
  };

  const content = <div>{getSimDate()}</div>;
  return (
    <div className="w-full h-full flex flex-col">
      <div className="w-full my-2 px-2 flex flex-col md:flex-row justify-between">
        <span className="text-md font-semibold">
          {props?.translate("main.info")}
        </span>
        <Popover content={content} title="Симний мэдээлэл" trigger="click">
          <Button
            icon={<InfoCircleOutlined />}
            type="link"
            className="px-0 w-fit"
          >
            Симний мэдээлэл харах
          </Button>
        </Popover>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 gap-2 w-full px-2">
        <Cards.InfoCard
          icon={iTemp}
          text={props?.translate("main.temprature")}
          type={1}
          value={
            props?.isDemo
              ? 20
              : props?.info[props?.data?.imei]?.temperature || 0
          }
        />
        <Cards.InfoCard
          icon={iVolt}
          text={props?.translate("main.voltage")}
          type={2}
          value={
            props?.isDemo ? 12.5 : props?.info[props?.data?.imei]?.volt || 0
          }
        />
        <Cards.InfoCard
          icon={iG}
          text={props?.translate("main.network")}
          type={3}
          value={props?.isDemo ? 26 : props?.info[props?.data?.imei]?.csq || 0}
        />
      </div>
      <div className="bg-light dark:bg-black py-2 rounded-2xl mt-2 p-2">
        <div className="w-full mb-2">
          <span className="text-md font-semibold">
            {props?.translate("main.settimer")}
          </span>
        </div>
        <div className="gap-3 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2">
          <Cards.TimerCard
            icon={iTime}
            loading={props?.istimerlist === true}
            text={props?.translate("sider.timer")}
            time={props?.timer}
            {...props}
          />
          <Cards.AlarmCard
            loading={props?.isalarmlist === true}
            icon={iTime}
            text={props?.translate("sider.alarm")}
            time={props?.alarm}
            {...props}
          />
        </div>
      </div>
    </div>
  );
};

export default Index;
